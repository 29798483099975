$.sticky = {
    init: function () {
        var _elem = document.getElementsByTagName('body')[0];

        if (window.scrollY >= 10) {
            _elem.classList.add('small-logo');
        }

        document.addEventListener('scroll', function (e) {
            var _ticking = false;

            if (!_ticking) {
                window.requestAnimationFrame(function () {
                    if (window.scrollY >= 10) {
                        _elem.classList.add('small-logo');
                    } else {
                        _elem.classList.remove('small-logo');
                    }

                    if (window.scrollY >= 70) {
                        _elem.classList.add('sticky');
                    } else {
                        _elem.classList.remove('sticky');
                    }

                    _ticking = false;
                });

                _ticking = true;
            }
        });
    },
};
