$.projects = {
    init: function () {
        this.filter();
        this.slider();
        this.title();
        this.map();
    },
    map: function () {
        var key = $('[data-maps-key]').data('mapsKey');

        if (!key) {
            return;
        }

        window['loadProjectMap'] = function () {
            var d = document,
                el = d.querySelector('.tx_supi__map .tx_supi__map-toggle-wrapper'),
                key = d.querySelector('[data-maps-key]').dataset.mapsKey,
                s,
                n;

            el.parentNode.removeChild(el);
            document.querySelector('.tx_supi__map .tx_supi__map-actual').style.display = '';

            if (key) {
                s = d.createElement('script');
                s.async = true;
                s.type = 'text/javascript';
                s.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&callback=initProjectMap&key=' + key;
                n = d.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(s, n);
            }
        };

        window['initProjectMap'] = function () {
            var infoboxContentContainer = document.getElementById('googleMapInfoBoxContent'),
                mapPosition = new google.maps.LatLng(
                    infoboxContentContainer.dataset.latitude,
                    infoboxContentContainer.dataset.longitude,
                ),
                mapOptions = {
                    center: mapPosition,
                    zoom: 17,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    scrollwheel: false,
                    navigationControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    draggable: true,
                    zoomControl: false,
                },
                map = new google.maps.Map(document.getElementById('projectlage'), mapOptions),
                marker = new google.maps.Marker({
                    position: mapPosition,
                    map: map,
                }),
                infoboxOptions = {
                    content: infoboxContentContainer.innerHTML,
                    boxStyle: {
                        border: 'none',
                        background: '#fff',
                        width: '500px',
                        padding: '10px 30px',
                    },
                    disableAutoPan: false,
                    pixelOffset: new google.maps.Size(-650, -20),
                    position: mapPosition,
                    closeBoxURL: '',
                    isHidden: false,
                    pane: 'mapPane',
                    enableEventPropagation: true,
                };

            new google.maps.InfoWindow(infoboxOptions).open(map);
        };
    },
    filter: function () {
        jQuery(function ($) {
            $('#projectListFilter')
                .find('select')
                .on('change', function () {
                    $('#projectListFilter').submit();
                });
            $('#projectListFilter .select ul').each(function (_, element) {
                $(element).on('scroll', function () {
                    updateScrollIndicator(element);
                });
            });
            $('#projectListFilter .select-field label').each(function (_, element) {
                selectOpenClassObserver.observe(element, {
                    attributes: true,
                    attributeFilter: ['class'],
                    childList: false,
                    subtree: false,
                });
            });
        });

        var selectOpenClassObserver = new MutationObserver(function (mutations) {
            for (var i in mutations) {
                var mutationTarget = $(mutations[i].target);
                if (mutationTarget.hasClass('open')) {
                    var list = mutationTarget.next().next().find(' ul').first();
                    updateScrollIndicator(list.get(0));
                }
            }
        });

        function updateScrollIndicator(listElement) {
            var scrollIndicator = $(listElement).siblings('.scroll-indicator');
            if (listElement.offsetHeight < listElement.scrollHeight) {
                if (!scrollIndicator.length) {
                    scrollIndicator = $('<div class="scroll-indicator"><span class="fa fa-chevron-down"></span></div>');
                    scrollIndicator.on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        listElement.scrollTo({
                            top: listElement.scrollHeight - listElement.offsetHeight,
                            behavior: 'smooth',
                        });
                    });
                    $(listElement).after(scrollIndicator);
                }
                $(listElement).parent().addClass('has-scroll-indicator');

                if (listElement.offsetHeight + listElement.scrollTop <= listElement.scrollHeight - 20) {
                    scrollIndicator.css('opacity', '1');
                } else {
                    scrollIndicator.css('opacity', '');
                }
            } else {
                $(listElement).parent().removeClass('has-scroll-indicator');
                scrollIndicator.remove();
            }
        }
    },
    slider: function () {
        var $window = $(window),
            $slider = $('.project.body-img-wrap.flexslider'),
            $current_slide = $('.slider-meta span.current'),
            $total_slides = $('.slider-meta span.total'),
            fl;

        if (!$slider.length) {
            return;
        }

        fl = $slider.flexslider({
            animation: 'slide',
            touch: false,
            controlNav: false,
            directionNav: true,
            useCSS: false,
            prevText: '<span class="ps-icon ps-icon-angle-left text-color-dark-grey"></span>',
            nextText: '<span class="ps-icon ps-icon-angle-right text-color-dark-grey"></span>',
            controlsContainer: $('.custom-controls-container'),

            before: function (e) {
                $window.trigger('flexsliderUpdate');
                $slider.find('i.fullscreen-icon').hide();
            },
            start: function (flexSlider) {
                var _count = $('<li class="count" />').text('1/' + flexSlider.count);

                flexSlider.next().find('ul li:first-child').after(_count);
            },
            after: function (flexSlider) {
                $slider.find('i.fullscreen-icon').show();
                flexSlider
                    .next()
                    .find('.count')
                    .text(flexSlider.currentSlide + 1 + '/' + flexSlider.count);
            },
        });
        $slider.data('flexslider', fl);

        $('.body-img-wrap ul.slides li:not(.clone) a.fullscreen').magnificPopup({
            type: 'image',
            gallery: {
                options: {
                    enabled: true,

                    preload: [0, 2],
                    navigateByImgClick: true,
                    arrows: true,
                    arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
                    tPrev: 'Previous (Left arrow key)',
                    tNext: 'Next (Right arrow key)',
                    tCounter: '%curr% of %total%',
                },
            },
            callbacks: {
                open: function () {
                    try {
                        $slider.flexslider('pause');
                    } catch (e) {}
                },
                close: function () {
                    try {
                        $slider.flexslider('play');
                    } catch (e) {}
                },
            },
        });
    },
    title: function () {
        $(window).on('resize', function () {
            var window_with = $(window).width();

            $('.tooltip').each(function (i, elem) {
                var $this = $(this);

                var diff = $this.offset().left + $this.outerWidth(true) - window_with;
                var parent_offset = $this.closest('.icon-wrapper').offset().left;
                var parent_width = $this.closest('.icon-wrapper').width();

                if (diff > 0) {
                    var inner_diff = parent_offset + $this.outerWidth(true) / 2 - window_with;

                    if (inner_diff > 0) {
                        $this.css('left', '-' + inner_diff + 'px');
                    } else {
                        $this.css('left', '0');
                    }
                } else {
                    if (parent_offset + parent_width / 2 + $this.outerWidth(true) / 2 + 1 < window_with)
                        $this.css('left', '');
                }
            });
        });
    },
};
