$.calendar = {
    init: function () {
        if (window.addtocalendar && typeof window.addtocalendar.start == 'function') return;

        if (!window.hasOwnProperty('ifaddtocalendar') && $('.addtocalendar').length) {
            window.ifaddtocalendar = 1;
            var d = document,
                s = d.createElement('script');

            s.type = 'text/javascript';
            s.charset = 'UTF-8';
            s.async = true;
            s.src = 'https://addtocalendar.com/atc/1.5/atc.min.js';

            var h = d.getElementsByTagName('script')[0];
            h.parentNode.insertBefore(s, h);
        }
    },
};
