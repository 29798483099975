$.dflipInit = {
    init: function () {
        $('body').on('click', '[data-dflip=dflip]', function (e) {
            var book = $(e.target).closest('[data-dflip=dflip]');
            var _path = book.data('dflipPath') || '/typo3conf/ext/theme/Resources/Public/dflip';

            if (!window.dfLightBox) {
                window.dfLightBox = new DFLightBox(function () {
                    if (window.location.hash.indexOf('#dflip-') == 0) window.location.hash = '#_';
                    window.dfActiveLightBoxBook.dispose();
                    window.dfActiveLightBoxBook = null;
                });
            }

            window.dfLightBox.duration = 500;

            if (window.dfActiveLightBoxBook && window.dfActiveLightBoxBook.dispose) {
                window.dfActiveLightBoxBook.dispose();
            } else {
                window.dfLightBox.show(function () {
                    var options = DFLIP.getOptions(book);
                    options.transparent = false;
                    options.id = book.attr('id');
                    options.source = book.attr('data-source');
                    options.soundEnable = false;
                    var slug = book.attr('slug');
                    if (slug != null) options.slug = slug;
                    options.isLightBox = true;
                    options.soundFile = _path + '/sound/turn2.mp3';

                    DFLIP.defaults.pdfjsSrc = _path + '/js/libs/pdf.min.js';
                    DFLIP.defaults.pdfjsCompatibilitySrc = _path + '/js/libs/compatibility.js';
                    DFLIP.defaults.pdfjsWorkerSrc = _path + '/js/libs/pdf.worker.min.js';
                    DFLIP.defaults.threejsSrc = _path + '/js/libs/three.min.js';
                    DFLIP.defaults.mockupjsSrc = _path + '/js/libs/mockup.min.js';

                    window.dfActiveLightBoxBook = $(window.dfLightBox.container).flipBook(options.source, options);
                });
            }

            e.preventDefault();
        });
    },
};
