(function ($) {
    'use strict';

    $.iframeHeight = new Object({
        init: function () {
            this.iframeHeight();
            this.supi();
        },

        supi: function () {
            var that = this;
            window.addEventListener(
                'serviceEmbeded',
                function (evt) {
                    let wrapper = document.getElementById('chart'),
                        chartFrame = document.getElementById('chartFrame');

                    if (wrapper) {
                        wrapper.classList.remove('d-none');
                        that.infoBar(chartFrame.dataset.src);
                        document.getElementById('chartFrame').remove();
                    }
                },
                false,
            );
        },

        infoBar: function (src) {
            if ($('#infobarIframe').length > 0) {
                var iFrame = [],
                    graphfileurl = src,
                    transport = new easyXDM.Socket({
                        remote: graphfileurl,
                        container: 'infobarIframe',
                        onMessage: function (message, origin) {
                            this.container.getElementsByTagName('iframe')[0].style.height = message + 'px';

                            iFrame = $('.infobar-graph #infobarIframe iframe');
                            iFrame.attr('scrolling', 'no').width(iFrame.parent().width());
                        },
                    });

                $(window).on('resize orientationchange', function () {
                    if (iFrame.length == 0) {
                        return;
                    }

                    iFrame.width(iFrame.parent().width());
                });
            }
        },

        iframeHeight: function () {
            if (!$('.single-graph iframe') || !$('#embedded').length) {
                return;
            }

            var transport = new easyXDM.Socket({
                remote: $('#embedded').data('url'),
                container: 'embedded',
                onMessage: function (message, origin) {
                    $('.single-graph iframe')
                        .attr('scrolling', 'no')
                        .css('height', message + 'px');
                },
                onReady: function () {
                    $('.single-graph-menu-loader').hide();
                    $('.single-graph iframe').addClass('show-me');
                },
            });
        },
    });
})(jQuery);
