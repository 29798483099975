(function ($) {
    'use strict';

    $.contactWrapper = new Object({
        init: function () {
            var _contacts = $('.content-container .col-additional .tx-parkside-contact').parent(),
                _spacerClass = [],
                _height = 0;

            // extract spacer classes from single elements and move that classes
            // to the wrapper
            // and calculate equal height
            _contacts.each(function () {
                var _hasSpacerClass = $(this).attr('class').indexOf('frame-space-'),
                    that = $(this);

                console.log($(this).find('.single-contact').outerHeight());

                if (_hasSpacerClass !== '-1') {
                    var _classNames = $(this).attr('class').split(' ');

                    $.each(_classNames, function (k, v) {
                        if (v.indexOf('frame-space-') !== -1) {
                            _spacerClass.push(v);
                            that.removeClass(v);
                        }
                    });
                }
            });

            _contacts.wrapAll(
                $('<div />').addClass('keep-row contact-wrapper clearfix mb-small').addClass(_spacerClass.join(' ')),
            );

            _contacts.each(function () {
                if (_height < $(this).find('.single-contact').outerHeight()) {
                    _height = $(this).find('.single-contact').outerHeight();
                }
            });

            _contacts.find('.single-contact').css('height', _height);
        },
    });
})(jQuery);
