$.youTube = {
    init: function () {
        // add eventlistener for supi event
        window.addEventListener(
            'youTubeAllowed',
            function (evt) {
                $('main div.video').addClass('aspect-ratio-xs aspect-ratio-16by9');
            },
            false,
        );
    },
};
