(function ($) {
    'use strict';

    $.pictureLibrary = new Object({
        init: function () {
            var $lastElement = $('.piclib-category-title').last().data('category'),
                $loader = $('.loader'),
                $loadMoreButton = $('.loadmore');

            if (typeof Storage !== 'undefined') {
                var _lastElement = localStorage.getItem('lastElement');

                if (_lastElement) {
                    var $lastElement = $('[data-category="' + _lastElement + '"]');

                    if ($lastElement.length > 0) {
                        $('html, body').animate(
                            {
                                scrollTop: parseInt($lastElement.offset().top),
                            },
                            0,
                            function () {
                                localStorage.removeItem('lastElement');
                            },
                        );
                    } else {
                        localStorage.removeItem('lastElement');
                    }
                }
            }

            $('.loadmore').click(function () {
                $loader.removeClass('hidden');
                $loadMoreButton.hide();
                $loader.addClass('loading');

                if (typeof Storage !== 'undefined') {
                    localStorage.setItem('lastElement', $lastElement);
                }
            });
        },
    });
})(jQuery);
