(function ($) {
    var CUSTOM_SELECT_ACTIVE_CLASS = 'search__select--active';
    var CUSTOM_SELECT_OPTIONS_ACTIVE_CLASS = 'search__select-options--active';
    var EXPANDABLE_FORM_ACTIVE_CLASS = 'search__form--expanded';

    $.search = new Object({
        init: function initSearch() {
            var customSelectLabels = $('.search__select-label--custom');
            var nativeSelects = $('.search__select-input');
            var expandableSearchForms = $('.search__form--expandable');

            nativeSelects.each(this.nativeSelect);
            customSelectLabels.each(this.customSelect);
            expandableSearchForms.each(this.searchForm);
        },
        nativeSelect: function initNativeSelect(_, selectElement) {
            var select = $(selectElement);
            select.on('change', function onNativeSelectChange(event) {
                if (!select.val()) {
                    return;
                }
                event.preventDefault();
                event.stopPropagation();

                goToLocation(select.val());
            });
        },
        customSelect: function initCustomSelect(_, selectElement) {
            var select = $(selectElement);
            var dropdown = select.next();
            var options = dropdown.find('.search__select-option');

            select.on('click', function onCustomSelectClick(event) {
                event.preventDefault();
                dropdown.toggleClass(CUSTOM_SELECT_OPTIONS_ACTIVE_CLASS);
                dropdown.parent().toggleClass(CUSTOM_SELECT_ACTIVE_CLASS);
            });
            select.on('keydown', function onCustomSelectKeydown(event) {
                if (event.code === 'Space') {
                    event.preventDefault();
                    dropdown.addClass(CUSTOM_SELECT_OPTIONS_ACTIVE_CLASS);
                    dropdown.parent().addClass(CUSTOM_SELECT_ACTIVE_CLASS);
                    dropdown.children().first().focus();
                }
            });
            select.parent().on('focusout', function onCustomSelectFocusOut(event) {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    dropdown.removeClass(CUSTOM_SELECT_OPTIONS_ACTIVE_CLASS);
                    dropdown.parent().removeClass(CUSTOM_SELECT_ACTIVE_CLASS);
                }
            });

            options.each(function initCustomSelectOption(_, optionElement) {
                var option = $(optionElement);
                option.on('keydown', function onCustomSelectOptionKeydown(event) {
                    if (
                        ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'Space', 'Enter', 'Tab', 'Escape'].includes(
                            event.code,
                        )
                    ) {
                        event.preventDefault();
                    }
                    switch (event.key) {
                        case 'ArrowDown':
                        case 'ArrowRight':
                            option.next().focus();
                            break;
                        case 'ArrowUp':
                        case 'ArrowLeft':
                            option.prev().focus();
                            break;
                        case 'Enter':
                            goToLocation(option.data('url'));
                            break;
                        case 'Tab':
                        case 'Escape':
                            select.focus();
                            dropdown.removeClass(CUSTOM_SELECT_OPTIONS_ACTIVE_CLASS);
                            dropdown.parent().removeClass(CUSTOM_SELECT_ACTIVE_CLASS);
                            break;
                    }
                });

                option.on('click', function onOptionClick() {
                    dropdown.removeClass(CUSTOM_SELECT_OPTIONS_ACTIVE_CLASS);
                    dropdown.parent().removeClass(CUSTOM_SELECT_ACTIVE_CLASS);
                    goToLocation(option.data('url'));
                });
            });
        },
        searchForm: function initSearchForm(_, formElement) {
            var form = $(formElement);

            // form.attr('novalidate', 'true');
            form.on('submit', function onFormSubmit(event) {
                if (!formElement.checkValidity() || !form.hasClass(EXPANDABLE_FORM_ACTIVE_CLASS)) {
                    event.preventDefault();

                    var formActionValue = form.attr('action');
                    var searchForms = $("form[action='" + formActionValue + "']");
                    if (form.toggleClass(EXPANDABLE_FORM_ACTIVE_CLASS)) {
                        form.get(0).elements[0].focus();
                    }
                }
            });

            form.on('focusout', function onFormFocusOut(event) {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                    form.removeClass(EXPANDABLE_FORM_ACTIVE_CLASS);
                }
            });

            form.find('.search__suggestions').on('pointerdown', function onSuggestionsPointerDown(event) {
                event.stopPropagation();
                event.preventDefault();
            });

            form.on('keydown', function onFormKeyDown(event) {
                if (event.code === 'Escape') {
                    event.preventDefault();
                    form.removeClass(EXPANDABLE_FORM_ACTIVE_CLASS);
                }
            });
        },
    });

    function goToLocation(uri) {
        var a = document.createElement('a');
        a.href = uri;
        a.click();
    }
})(jQuery);
