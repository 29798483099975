var $body = $(document.body);
var navHeight = $('.navbar').outerHeight(true) + 10;
var footerSection = $('.footer-section').outerHeight(true);
//console.log($('footer').outerHeight(true));

$('.bs-sidenav').affix({
    offset: {
        top: function () {
            var offsetTop = $('.bs-sidenav').offset().top;
            var sideBarMargin = parseInt($('.bs-sidenav').children(0).css('margin-top'), 10);
            var navOuterHeight = $('.hero').height();
            return (this.top = offsetTop - navOuterHeight - sideBarMargin);
        },
        bottom: function () {
            return (this.bottom = $('footer').outerHeight(true));
        },
    },
});

$body.scrollspy({
    target: '.col-menu',
    offset: navHeight,
});
