// ------------------------------------------------------------------------------------------
// PageSlider
// ------------------------------------------------------------------------------------------
(function ($) {
    var isElementInViewport = function (el) {
            var rect;
            if (el === undefined) return;
            rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 && rect.left >= 0 && rect.bottom <= $(window).height() && rect.right <= $(window).width()
            );
        },
        $tl = $('.top-link');

    $tl.click(function (e) {
        e.preventDefault();
        $('body,html').animate(
            {
                scrollTop: 0,
            },
            'slow',
        );
        return false;
    });

    var toggleTopLink = function () {
        $tl.removeClass('hide');
        //$tl.toggleClass('hide', $tl.position().top - $(window).height() < 130);
    };

    $(document).ready(toggleTopLink);
    $(window).load(toggleTopLink); // for images which take too long to load

    $('.scroll-down').click(function (event) {
        var offset = $(this).offset(),
            scrolltop = $('body').scrollTop(),
            bottompos = $(document).height() - $(window).height() - 20;
        if (Math.abs(offset.top - scrolltop) > 10 && scrolltop !== bottompos) {
            $('body,html').animate(
                {
                    scrollTop: offset.top,
                },
                'slow',
            );
        }
    });
})(jQuery);

function PageSlider($slider, portfolio) {
    // dont resize slider elements on projects page
    if ($slider.hasClass('project')) {
        return;
    }

    var animation = portfolio ? 'fade' : 'slide';
    var controlNav = portfolio ? false : true;
    var directionNav = portfolio ? false : true;
    var touch = portfolio ? false : true;

    var $window = $(window),
        minHeight = parseInt($slider.attr('data-min_height'), 10),
        maxHeight = parseInt($slider.attr('data-max_height'), 10),
        responsive_container = parseInt($slider.attr('data-responsive'), 10);

    if (isNaN(responsive_container)) responsive_container = 0;

    function resizeSliderHeight() {
        var offset = $slider.offset(),
            windowHeight = $window.height(),
            sliderHeight;

        if (isNaN(minHeight)) minHeight = 465;

        if (isNaN(maxHeight)) maxHeight = 465;

        // resize slider
        if (offset.top < windowHeight)
            sliderHeight = Math.max(
                minHeight,
                maxHeight > 0 ? Math.min(maxHeight, windowHeight - offset.top) : windowHeight - offset.top,
            );
        else sliderHeight = maxHeight > 0 ? Math.max(minHeight, maxHeight) : minHeight;

        $slider.height(sliderHeight);
    }
    resizeSliderHeight();

    $(window).load(function () {
        var resized = 0;

        function resizeSliderItems() {
            resizeSliderHeight();

            var $items = $slider.find('.slides > li');

            // resize images
            $.each($items, function (i) {
                var $item = $(this),
                    itemWidth = $item.width(),
                    itemHeight = $item.height(),
                    $img = $item.find('.slider_image img'),
                    originalWidth = $img.attr('width'),
                    originalHeight = $img.attr('height'),
                    ratio = originalWidth / originalHeight,
                    styles = {
                        width: originalWidth,
                        height: originalHeight,
                    },
                    horizontalAlignment = $item.attr('data-horizontal-alignment'),
                    verticalAlignment = $item.attr('data-vertical-alignment');

                // image scaling
                var scalingVar = $item.attr('data-scale');
                var isMobile = getViewportWidth() < mobileNavBreakpointX;
                var isMobileScaling = $item.attr('data-scale-for-mobile') == 1;

                if ((isMobile && isMobileScaling) || responsive_container) {
                    scalingVar = '100% auto';

                    if (isMobile && isMobileScaling) {
                        horizontalAlignment = 'left';
                        verticalAlignment = 'top';
                        styles.marginLeft = 0;
                        styles.marginTop = 0;
                    }
                }

                switch (scalingVar) {
                    case '100% auto':
                        styles.width = itemWidth;
                        styles.height = styles.width / ratio;
                        break;

                    case 'auto 100%':
                        styles.height = itemHeight;
                        styles.width = styles.height * ratio;
                        break;

                    case 'contain':
                        if (styles.width > itemWidth) {
                            styles.width = itemWidth;
                            styles.height = styles.width / ratio;
                        }

                        if (styles.height > itemHeight) {
                            styles.height = itemHeight;
                            styles.width = styles.height * ratio;
                        }

                        break;

                    case '0 0':
                        styles.width = originalWidth;
                        styles.height = originalHeight;
                        break;

                    case 'cover':
                    default:
                        styles.width = itemWidth;
                        styles.height = styles.width / ratio;

                        if (styles.height < itemHeight) {
                            styles.height = itemHeight;
                            styles.width = styles.height * ratio;
                        }
                }

                var max_height_portfolio = 0;

                //resize the portfolio slider only to the smallest images
                if (portfolio) {
                    var $tmp_items = $slider.find('.slides > li img');

                    $tmp_items.each(function (index) {
                        if (index === 0) {
                            max_height_portfolio = $(this).height();
                            return;
                        }

                        if ($(this).height() < max_height_portfolio) {
                            max_height_portfolio = $(this).height();
                        }
                    });
                }

                if ((isMobile && isMobileScaling) || responsive_container) {
                    if (portfolio) {
                        if (max_height_portfolio > maxHeight) {
                            $slider.height(maxHeight);
                        } else if (styles.height > max_height_portfolio) $slider.height(max_height_portfolio);
                        else $slider.height(styles.height);

                        verticalAlignment = 'middle';
                    } else {
                        if (styles.height > maxHeight) {
                            $slider.height(maxHeight);

                            if (isMobile && isMobileScaling) verticalAlignment = 'middle';
                        } else $slider.height(styles.height);
                    }
                }

                styles.width = Math.round(styles.width);
                styles.height = Math.round(styles.height);

                // horizontal alignment
                styles.position = 'absolute';
                styles.left = horizontalAlignment == 'left' ? 0 : 'auto';
                styles.right = horizontalAlignment == 'right' ? 0 : 'auto';

                if (horizontalAlignment == 'center') {
                    styles.left = '50%';
                    styles.marginLeft = Math.round(styles.width / -2);
                }

                // vertical alignment
                styles.top = verticalAlignment == 'top' ? 0 : 'auto';
                styles.bottom = verticalAlignment == 'bottom' ? 0 : 'auto';

                if (verticalAlignment == 'middle') {
                    styles.top = '50%';
                    styles.marginTop = Math.round(styles.height / -2);
                }

                if ($item.attr('data-scale') === '0 0') {
                    // styles.marginTop -= 67;
                }

                $img.css(styles);

                /// grid
                //$img = $img.parents(".slider_image").siblings('[class^=shadow-grid]');
                //                      if ($img.length) {
                //                          originalWidth = $img.attr("width");
                //                          originalHeight = $img.attr("height");
                //                          ratio = originalWidth / originalHeight;
                //                          styles = {
                //                              width : originalWidth,
                //                              height : originalHeight
                //                          };
                //
                //                          // do cover
                //                          styles.width = itemWidth;
                //                          styles.height = styles.width / ratio;
                //
                //                          if(styles.height < itemHeight) {
                //                              styles.height = itemHeight;
                //                              styles.width = styles.height * ratio;
                //                          }
                //
                //                          styles.top = "50%";
                //                          styles.marginTop = Math.round(styles.height / -2);
                //                          styles.left = "50%";
                //                          styles.marginLeft = Math.round(styles.width / -2);
                //
                //                          $img.css(styles);
                //                      }
                //
            });

            if (!resized) {
                resized = 1;
            }
        }

        $slider.flexslider({
            animation: animation,
            controlNav: controlNav,
            directionNav: directionNav,
            prevText: '<span class="flex-prev ps-icon ps-icon-angle-left"></span>',
            nextText: '<span class="flex-next ps-icon ps-icon-angle-right"></span>',
            touch: touch,
            before: function (slider) {
                $window.trigger('flexsliderUpdate', [slider]);
            },
            start: function (slider) {
                resizeSliderItems();
                $window.trigger('flexsliderStart', [slider]);
                $window.on('resize orientationchange flexsliderUpdate', resizeSliderItems);
            },
            after: function (slider) {
                resizeSliderItems();
            },
        });
    });
}

function styleSubmitButtons() {
    $.each(
        $(
            '.search_button.start input[type="submit"], .search_button.new input[type="submit"], .post-password-form input[type=submit]',
        ),
        function () {
            var $button = $(this);

            if ($button.next('.submit_button_arrow').length == 0) {
                var $submitButtonArrow = $('<div class="submit_button_arrow"></div>').click(function (e) {
                    e.preventDefault();
                    $button.click();
                });

                $button.after($submitButtonArrow);
            }
        },
    );
}

$(function () {
    styleSubmitButtons();
});
