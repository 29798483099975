(function ($) {
    $(function () {
        $(document).ready(function () {
            /**
             * Activate TB tooltip which must be done explicitly since this is an opt-in plugin.
             */
            $('.nav-top li a').tooltip();
        });
    });
})(jQuery);
