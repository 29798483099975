$.fn.audio = function () {
    const audioElement = this;

    const playButton = audioElement.find('.audio__playpause');
    const currentTime = audioElement.find('.audio__time--current');
    const totalTime = audioElement.find('.audio__time--total');
    const progress = audioElement.find('.audio__progress');
    const volume = audioElement.find('.audio__volume');
    const playText = audioElement.find('.audio__actiontext--play');
    const pauseText = audioElement.find('.audio__actiontext--pause');
    const player = audioElement.find('.audio__player');
    const playerElement = player.get(0);
    const volumeLoud = audioElement.find('.audio__volumetext--loud');
    const volumeSilent = audioElement.find('.audio__volumetext--silent');

    let resumePlayAfterDrag = false;

    player.on('durationchange', () => {
        const minutes = Math.floor(playerElement.duration / 60);
        const seconds = Math.floor(playerElement.duration - minutes * 60)
            .toString()
            .padStart(2, '0');
        totalTime.text(`${minutes}:${seconds}`);
    });

    player.on('timeupdate', () => {
        const minutes = Math.floor(playerElement.currentTime / 60);
        const seconds = Math.floor(playerElement.currentTime - minutes * 60)
            .toString()
            .padStart(2, '0');
        const progressValue = (playerElement.currentTime / playerElement.duration) * 100;
        currentTime.text(`${minutes}:${seconds}`);
        progress.attr('value', progressValue);
    });

    playButton.on('click', () => {
        if (playerElement.paused) {
            playerElement.play();
        } else {
            playerElement.pause();
        }
    });

    progress.on('pointerdown', () => {
        if (!playerElement.paused) {
            playerElement.pause();
            resumePlayAfterDrag = true;
        }
        progress.on('pointermove', onProgressMove);
    });

    $(document).on('pointerup', () => {
        progress.off('pointermove', onProgressMove);
        if (resumePlayAfterDrag) {
            playerElement.play();
            resumePlayAfterDrag = false;
        }
    });

    function onProgressMove({ originalEvent: event }) {
        playerElement.currentTime = (event.offsetX / event.target.clientWidth) * playerElement.duration;
    }

    player.on('play pause', () => {
        playText.toggleClass('hidden', !playerElement.paused);
        pauseText.toggleClass('hidden', playerElement.paused);
    });

    player.on('volumechange', () => {
        volumeLoud.toggleClass('hidden', playerElement.volume === 0);
        volumeSilent.toggleClass('hidden', playerElement.volume !== 0);
    });

    volume.on('click', () => {
        if (playerElement.volume > 0) {
            playerElement.volume = 0;
        } else {
            playerElement.volume = 1;
        }
    });
};
