(function ($) {
    'use strict';

    $.fifftyFifftyWrapper = new Object({
        init: function () {
            $('.frame-type-header.frame-layout-2').each(function () {
                var _elemToWrap = $(this).nextUntil('.frame-type-header');

                _elemToWrap.addClass('col-xs-12 col-md-6').wrapAll($('<div />').addClass('keep-row clearfix'));
            });
        },
    });
})(jQuery);
