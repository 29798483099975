window['loadMaps2'] = function () {
    var wrapper = document.querySelector('[data-google-maps-script]'),
        gooogleMapsScriptPath = wrapper.dataset.googleMapsScript,
        maps2ScriptPath = wrapper.dataset.maps2Script,
        firstScript = document.getElementsByTagName('script')[0],
        googleScript,
        maps2Script;

    wrapper.parentNode.removeChild(wrapper);

    // Maps2 has no callback, so we load it syncronously
    maps2Script = document.createElement('script');
    maps2Script.src = maps2ScriptPath;
    firstScript.parentNode.insertBefore(maps2Script, firstScript);

    // Now load google async
    googleScript = document.createElement('script');
    googleScript.async = true;
    googleScript.src = gooogleMapsScriptPath;
    maps2Script.parentNode.insertBefore(googleScript, maps2Script);
};
