$.burgerNavigation = {
    init: function () {
        $('button.toggle-mobile-navigation').on('click', function (e) {
            $('body').toggleClass('show-mobile');
        });

        $('button.toggle-sub-navigation').on('click', function (e) {
            if ($(this).closest('li').hasClass('toggle')) {
                $(this).closest('li').removeClass('toggle');
                return void 0;
            }

            $('.toggle-sub-navigation').closest().removeClass('toggle');
            $(this).closest('li').addClass('toggle');
        });
    },
};

/** @TODO: A11Y: Makeing the Burgermenu accessible **/
