/**
 * upgrade js refactor file
 */

var isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);

if (isIE11) {
    (function () {
        if (typeof window.CustomEvent === 'function') return false; //If not IE

        function CustomEvent(event, params) {
            params = params || { bubbles: false, cancelable: false, detail: undefined };
            var evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        }

        CustomEvent.prototype = window.Event.prototype;

        window.CustomEvent = CustomEvent;
    })();
}

try {
    videojs.options.flash.swf = '/typo3conf/ext/theme/Resources/Public/JavaScript/video-js.swf';
} catch (e) {
    console.log(e);
}

jQuery(function ($) {
    $.teaser.init();
    $.lightbox.init();
    $.contactWrapper.init();
    $.iframeHeight.init();
    $.fancySelectWrapper.init();
    $.pictureLibrary.init();
    $.fifftyFifftyWrapper.init();
    $.Slider.init();
    $.calendar.init();
    $.projects.init();
    $.sticky.init();
    $.burgerNavigation.init();
    $.youTube.init();
    $.dflipInit.init();
    $.search.init();
    $.toTop.init();
    $('.audio').audio();

    // replace possible rte links for changing supi settings to a button element
    // due to changes in the supi ext to not listen to anchor tags anymore (a11y issues)
    // $('a.supi-link[href*="#supi-choose"]').each(function () {
    //     $(this).replaceWith($('<button href="#supi-choose" class="supi-link">' + $(this).html() + '</button>'));
    // });

    $('.js-lightbox').magnificPopup({
        type: 'inline',
    });
});
