$.toTop = {
    scrollToTop: function () {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
    },

    init: function () {
        var rootElement = document.documentElement,
            scrollToTopBtn = document.getElementById('scrollToTopBtn');

        document.addEventListener(
            'scroll',
            function () {
                // Do something on scroll
                var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
                if (rootElement.scrollTop / scrollTotal > 0.4) {
                    // Show button
                    scrollToTopBtn.classList.add('show');
                } else {
                    // Hide button
                    scrollToTopBtn.classList.remove('show');
                }
            },
            false,
        );

        scrollToTopBtn.addEventListener('click', $.toTop.scrollToTop);
    },
};
