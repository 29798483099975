(function ($) {
    'use strict';

    $.teaser = new Object({
        init: function () {
            this.gutter();
            this.noHover();
        },

        gutter: function () {
            var _teaser = $('.teaser-gutter');

            _teaser.wrapAll($('<div />').addClass('keep-row teaser-gutter-wrapper').addClass(_teaser.data('spacer')));
        },

        noHover: function () {
            var _tiles = $('.teaser-tile');

            _tiles.each(function () {
                var _count = $(this).find('.back').length;
                if (_count === 0) {
                    $(this).addClass('nbc');
                }
            });
        },
    });
})(jQuery);
