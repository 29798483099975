(function ($) {
    'use strict';

    $.fancySelectWrapper = new Object({
        init: function () {
            if ($.fn.fancySelect) {
                $('.project-filter .select-field label').fancySelect({
                    onChange: function () {
                        var val = this.$sel.val();
                        this.$span.text(val === '' ? '' : this.$sel.find('option:selected').text());
                        val === '' ? this.$lbl.find('span.default').show() : this.$lbl.find('span.default').hide();

                        if (val && this.changed && this.$sel.data('jump')) {
                            location.href = val;
                        }

                        if (val && this.changed && this.$sel.data('submit')) {
                            this.$sel.closest('form').submit();
                        }

                        this.changed = true;
                    },
                });

                $('.news-menu-view label').fancySelect({
                    onChange: function () {
                        var val = this.$sel.val();
                        this.$span.text(val === '' ? '' : this.$sel.find('option:selected').text());
                        val === '' ? this.$lbl.find('span.default').show() : this.$lbl.find('span.default').hide();

                        if (!this.init) {
                            if (val === '') {
                                var currentUrl = new URL(window.location.href);
                                currentUrl.search = '';
                                window.location.href = currentUrl.toString();
                            } else {
                                window.location.href = val;
                            }
                        }

                        this.init = false;
                    },
                });

                $('.branches__label').fancySelect({
                    onChange: function () {
                        var val = this.$sel.val();
                        this.$span.text(val === '' ? '' : this.$sel.find('option:selected').text());
                        val === '' ? this.$lbl.find('span.default').show() : this.$lbl.find('span.default').hide();

                        if (val && this.changed) {
                            window.location = val;
                        }

                        this.changed = true;
                    },
                });
            }
        },
    });
})(jQuery);
