/** @Todo: Flexslider as a library is not accessible */
(function ($) {
    'use strict';

    $.Slider = new Object({
        init: function () {
            this.storySlider();
            this.pageSlider();
            this.projectSlider();
            this.eventDetailSlider();
            this.flexslider();
            this.heroSlider();
        },
        heroSlider: function () {
            var _heroSlider = $('.hero-slider .hero-slider__wrap');

            if (_heroSlider.find('.slides li').length > 1) {
                _heroSlider.flexslider({
                    animation: 'slide',
                    touch: true,
                    controlNav: false,
                    directionNav: true,
                    useCSS: true,
                    prevText: '<span class="sr-only">Zurück</span>' /** @TODO: translate **/,
                    nextText: '<span class="sr-only">Weiter</span>' /** @TODO: translate **/,
                });
            }
        },
        storySlider: function () {
            var sliders = $('.tmpl-Story .body-img-wrap.flexslider');

            if (sliders.length) {
                sliders.flexslider({
                    animation: 'slide',
                    touch: false,
                    controlNav: false,
                    directionNav: true,
                    useCSS: false,
                    prevText: '<span class="ps-icon ps-icon-angle-left white-color"></span>',
                    nextText: '<span class="ps-icon ps-icon-angle-right white-color"></span>',
                });
            }
        },
        pageSlider: function () {
            var sliders = $('.flexslider');

            if (sliders.length) {
                new PageSlider(sliders);
            }
        },
        projectSlider: function () {
            var slides = $('.header-img-wrap.flexslider');

            if (slides.length) {
                new PageSlider(slides, true);
            }
        },
        eventDetailSlider: function () {
            $(document).on('click', '[data-toggle=expand]', function (e) {
                var target = $(e.target);

                $(target.data('expand')).stop().slideDown(130);
                $(target.data('reduce')).stop().slideUp(130);

                e.preventDefault();
            });
        },
        flexslider: function () {
            var $slider = $('[data-slider]'),
                $current_slide = $('.slider-meta span.current'),
                $total_slides = $('.slider-meta span.total');

            $slider.flexslider({
                animation: 'slide',
                touch: false,
                controlNav: false,
                directionNav: true,
                useCSS: false,
                prevText: '<span class="ps-icon ps-icon-angle-left white-color"></span>',
                nextText: '<span class="ps-icon ps-icon-angle-right white-color"></span>',
                before: function (flexSlider) {
                    $(window).trigger('flexsliderUpdate');
                    $.flexsliderpreload($slider, flexSlider);
                    $slider.find('a.fullscreen-icon').hide();
                },
                start: function (flexSlider) {
                    $.flexsliderpreload($slider, flexSlider);
                    $.flexsliderresize($slider, flexSlider);

                    $current_slide.html(flexSlider.currentSlide + 1);
                    $total_slides.html(flexSlider.count);
                },
                after: function (flexSlider) {
                    $current_slide.html(flexSlider.currentSlide + 1);
                    $total_slides.html(flexSlider.count);
                    $slider.find('a.fullscreen-icon').show();
                },
            });
        },
    });
})(jQuery);
