(function ($) {
    'use strict';

    $.lightbox = new Object({
        init: function () {
            $('.simplyimage-popup').magnificPopup({
                type: 'image',
            });

            $(
                '.tmpl-Story .slider-holder li:not(.clone) .fullscreen, .tmpl-Story .story-imagetext .fullscreen',
            ).magnificPopup({
                type: 'image',
                gallery: {
                    options: {
                        enabled: true,
                        preload: [0, 2],
                        navigateByImgClick: true,
                        arrows: true,
                        arrowMarkup:
                            '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
                        tPrev: 'Previous (Left arrow key)',
                        tNext: 'Next (Right arrow key)',
                        tCounter: '%curr% of %total%',
                    },
                },
                callbacks: {
                    open: function () {
                        if ($('[data-slider]').length > 0) {
                            $('[data-slider]').flexslider('pause');
                        }
                    },
                    close: function () {
                        if ($('[data-slider]').length > 0) {
                            $('[data-slider]').flexslider('play');
                        }
                    },
                },
            });
        },
    });
})(jQuery);
